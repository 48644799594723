import React from 'react'
import styles from './Hero.module.css'

function Hero() {
    return (
        <div  style={{backgroundImage: "url('/images/hero.jpg')", backgroundSize: "cover"}}>
            <div className={styles.gridContainer}>
                <div>
                    <h1 className={styles.headLine}>Minería en México</h1>
                </div>
            </div>
        </div>
    )
}

export default Hero
