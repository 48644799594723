import React from 'react';
import styles from './Footer.module.css'
import { AiOutlineLinkedin, AiOutlineFacebook, AiOutlineInstagram } from 'react-icons/ai'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.mainFooter}>
        <p>Col. Ex Hacienda Los Ángeles #345</p>
        <p>Torreón Coah. CP:27077</p>
        <p>contacto@mcimining.com</p>
        <p>871 353 3542</p>
        <a href="" ><AiOutlineLinkedin /></a>
        <a href="" style={{ margin: "0 1vw" }}><AiOutlineInstagram /></a>
        <a href=""><AiOutlineFacebook /></a>
      </div>
      <div>

        <ul>Boletín
          <li><a href=""> Regístrate </a></li>
        </ul>

      </div>
      <div>
        <ul>Soluciones
          <li><a href=""> Venta de Maquinaria </a></li>
          <li><a href=""> Servicio de reparación </a></li>
          <li><a href=""> Renta </a></li>
          <li><a href=""> Asesoría </a></li>
        </ul>
      </div>
      <div>
        <ul>Acerca de MCI
          <li><a href=""> Nosotros </a></li>
          <li><a href=""> Historia </a></li>
          <li><a href=""> Blog </a></li>
          <li><a href=""> Aviso de privacidad </a></li>
        </ul>
      </div>
      <div>
        <ul>Contacto
          <li><a href=""> Cotiza </a></li>
          <li><a href=""> Centro de atención </a></li>
          <li><a href=""> Proveedores </a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;