import React from 'react'
import Cardette from './Card'
import styles from './Card.module.css'

function Dashboard() {
    return (
        <div className={styles.pageContent}>
            <Cardette title="Excavadora de minería" text="Potencia y precisión. Diseñado para maximizar la productividad en las operaciones mineras." image="https://images.unsplash.com/photo-1586161148512-64a1b3dac527?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=843&q=80" />
            <Cardette title="Camión volquete para minería" text="Capacidad y resistencia para transportar grandes cargas en terrenos mineros desafiantes." image="https://images.unsplash.com/photo-1523660778745-247ed0bcce31?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" />
            <Cardette title="Taladro de perforación" text="Innovación y fiabilidad. Imprescindible para la exploración y extracción de minerales." image="https://images.unsplash.com/photo-1622645636770-11fbf0611463?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=873&q=80" />
            <Cardette title="Equipo de trituración y cribado" text="Potente rendimiento y versatilidad. Procezamiento de materiales en la industria minera." image="https://images.unsplash.com/photo-1582280871722-424e91cbee8b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"/>
        </div>
    )
}

export default Dashboard
