import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import MainNav from './components/MainNav/MainNav';
import Hero from './components/Hero/Hero';
import Dashboard from './components/Dashboard/Dashboard';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div>
      <MainNav/>
      <Hero/>
      <Dashboard/>
      <Footer/>
    </div>
  );
}

export default App;
